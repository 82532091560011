import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/index-section1";
import Section4 from "../sections/index-section4";
import Section2 from "../sections/index-section2";
import Section3 from "../sections/index-section3";
import WomenbizSection from "../sections/he-for-she-section6";
import Section5 from "../sections/index-section5-multiple-use";
import Section6 from "../sections/index-section6-multiple-use";
import SEO from "../components/seo"

export default class Home extends React.Component{
  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = `
    var anchor1 = document.getElementById('Homepage1-CheckOutMentorship-Button');
    anchor1.onclick = function() {
            fbq('track', 'Homepage', {page: "Homepage", action: "Homepage1-CheckOutMentorship-Button"});
    };
    var anchor2 = document.getElementById('Homepage1-JoinMentor-Button');
    anchor2.onclick = function() {
             fbq('track', 'Homepage', {page: "Homepage", action: "Homepage1-JoinMentor-Button"});
    };
    var anchor5 = document.getElementById('WomenbizSection-GettoKnowProgram-Button');
    anchor5.onclick = function() {
            fbq('track', 'Homepage', {page: "Homepage", action: "WomenbizSection-GettoKnowProgram-Button"});
    };
    var anchor6 = document.getElementById('WomenbizSection-MeetMentors-Button');
    anchor6.onclick = function() {
             fbq('track', 'Homepage', {page: "Homepage", action: "WomenbizSection-MeetMentors-Button"});
    };
    var anchor7 = document.getElementById('Homepage5-BecomeMentor-Button');
    anchor7.onclick = function() {
            fbq('track', 'Homepage', {page: "Homepage", action: "Homepage5-BecomeMentor-Button"});
    };
    var anchor8 = document.getElementById('Homepage5-ApplyMaleMentor-Button');
    anchor8.onclick = function() {
             fbq('track', 'Homepage', {page: "Homepage", action: "Homepage5-ApplyMaleMentor-Button"});
    };
    `
    ;
    document.body.appendChild(s);
  }

  render(){
  return (
    <Layout 
      LogoColor={props => props.theme.colors.white} 
      HeaderMenuColor={props => props.theme.colors.white} 
      firstSectionBGColor={props => props.theme.colors.primary_violet}

      //button
      HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_violet.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_violet.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_violet.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.background_color}
    >
      <SEO title={"Bad-ass. Connected. Financially-independent."} description={"Build meaningful connections for women to achieve their professional and personal goals"} />
      
      <Section1></Section1>
      <Section4></Section4>
      <Section3></Section3>
    <WomenbizSection
      BulletPointsContent = {[
        "1:1 consultations and small group consultations (for both men and women) with experienced professionals from various industries.",
        "Monthly virtual interactive classes with a group of min. 50 participants to learn about a specific topic.",
        "Executive 1:1 and group self-development coaching ran by certified ICTs and companies.",
      ]}
      RowReverse = {true}
    />
      <Section2></Section2>
      <Section5
        DisplaySection='block'
      >
      </Section5>
      <Section6
        Background = {props => props.theme.colors.accent_yellow70}
        TextColor = {props => props.theme.colors.gray_dark2}
        ColorHeart = {props => props.theme.colors.accent_yellow_dark}

        ButtonColor={props => props.theme.buttons.primary_button_violet.normal.color} 
        ButtonBorder={props => props.theme.buttons.primary_button_violet.normal.border_color} 
        ButtonBackground={props => props.theme.buttons.primary_button_violet.normal.background_color}
        
        ButtonColorHover={props => props.theme.buttons.primary_button_violet.hovered.color}
        ButtonBorderHover={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
        ButtonBackgroundHover={props => props.theme.buttons.primary_button_violet.hovered.background_color}

        ButtonColorPressed={props => props.theme.buttons.primary_button_violet.pressed.color}
        ButtonBorderPressed={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
        ButtonBackgroundPressed={props => props.theme.buttons.primary_button_violet.pressed.background_color}
      ></Section6>
    </Layout>   
  )
}
}