import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Typed from 'react-typed';

const FirstSectionContainer = styled.div`
  background-color: ${props => props.theme.colors.primary_violet};
  @media(min-width:1200px){
    padding-top: 150px;
  }
  @media(max-width:1199px){
    padding-top: 70px;
  }
  @media(max-width:767px){
    padding-top: 0px;
    padding-bottom: 50px;
  }
`
const HomeTitle = styled.div`
  color: ${props => props.theme.colors.accent_yellow};
  @media(min-width:1200px){
    font: 98px/102px Abril Fatface;
    min-height:204px;
  }
  @media(max-width:1199px){
    font: 98px/102px Abril Fatface;
    min-height:408px;
  }
  @media(max-width:991px){
    font: 98px/102px Abril Fatface;
    min-height:408px;
    margin-top: 10px;
  }
  @media(max-width:767px){
    font: 3.3rem/4rem Abril Fatface;
    min-height:213px;
  }
  @media(max-width:399px){
    font: 3.3rem/4rem Abril Fatface;
    min-height:284px;
  }
  @media(max-width:320px){
    font: 3rem Abril Fatface;
    min-height:276px;
  }
`
const HomeSubtitle = styled.div`
  margin-top: 35px;
  color: ${props => props.theme.colors.white};
  font: 20px/28px Roboto;
  text-align: justify;
  @media(max-width:767px){
    margin-top: 20px;
  }
`
const ButtonFill1 = styled(Button)`
  width:100%;
  margin-top:25px;
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_yellow_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    margin-top:20px;
  }
`
const ButtonOutline1 = styled(Button)`
  margin-top:25px;
  height: 60px;
  color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.color};
  background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1200px){
    margin-left:-5px;
  }
  @media(max-width:1199px){
    width:100%;
  }
  @media(max-width:767px){
    margin-top:20px;
  }
`

export default function IndexSection1() {
  return (
  <FirstSectionContainer>
    <Container>
      <Row>
        <Col>
          <HomeTitle>
            <Typed
                strings={[
                "Grow with Indonesia’s top leaders.",
                "Reach your financially- independent future.",
                "Kick-start your career and business ideas."]}
                typeSpeed={40}
                backSpeed={20}
                loop >
            </Typed>
          </HomeTitle>
        </Col>
      </Row>
      <Row>
        <Col xl="8">
          <HomeSubtitle>
            Have you ever wondered how you can reach your peak potential, get advice and connections beyond your current network, be financially independent, or simply where to go after you graduate? <b>Join WomenWorks network and mentorship programs to grow both professionally and personally.</b>
          </HomeSubtitle>
        </Col>
      </Row>
      <Row css={css`
        @media(min-width:768px){
          margin-bottom:147px;
          :after {
            background-image: url('/page-home-section1-bg1.svg');
            content: '';
            display: block;
            position: absolute;
            width: 516px;
            height: 495px;
            top: 70px; //plus 983 from 1st section 
            right:0;
            z-index:1;
          }
        }
        @media(min-width:992px) {
          :after {
            top:0x; //plus 760 from 1st section 
          }
        }
        @media(min-width:1200px) {
          :after {
            top:510px;
          }
        }
        @media(min-width:1600px) {
          :after {
            display:none;
          }
        }
      `}>
        <Col sm lg="5" xl="5" >
          <Link id="Homepage1-CheckOutMentorship-Button" to={`/subscription/`}>
            <ButtonFill1 variant="warning">Check out our Mentorship Programs</ButtonFill1>
          </Link>
        </Col>
        <Col sm lg="3" xl="3">
        <OutboundLink
          id="Homepage1-JoinMentor-Button"
          target="_blank"
          href={`https://bit.ly/womenworksmentorsignupnew`} style={{"margin-bottom": "146px"}}>
            <ButtonOutline1 variant="outline-warning">Join as a Mentor</ButtonOutline1>
        </OutboundLink>
        </Col>  
        <Col xl="1">
        </Col>
      </Row>
    </Container>
  </FirstSectionContainer>
  )
}